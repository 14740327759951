let ValidadorForms = class {
    flag = false;
    #expresiones = {
        usuario: /^[a-zA-Z0-9\_\-]{4,16}$/, // Letras, numeros, guion y guion_bajo
        nombre3: /^[a-zA-Z0-9\_\-\.]{1,40}$/, // Letras, numeros, guion y guion_bajo
        nombreEscaner: /^[a-zA-ZÀ-ÿ0-9\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
        nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
        nombre2: /^[a-zA-Z\s]{1,40}$/, // Letras y espacios.
        nombreEspecial: /^[a-zA-ZÀ-ÿ0-9\_\-\s]{1,40}$/, // letras, acentos , numeros , guio, guion bajo y espacio
        password:
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%#?,.&])[A-Za-z\d@$!%*#?&.,]{4,}$/, //Mínimo 4 caracteres, al menos una letra, un número y un carácter especia
        correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, // formato base d un correo
        telefono: /^[0-9\+]{7,14}$/, // 7 a 14 numeros incluye el +.
        select: /^[a-zA-Z0-9\_\-]{1,100}/, // Letras, numeros, guion y guion_bajo de 1 a 100
        texto: /^[a-zA-ZÀ-ÿ0-9\s\-\_\,\.]{0,500}$/, // letras, numeros, especio, guio y guion bajo , de 0 a 200 caracteres
        alfa: /^[a-zA-Z0-9]{1,40}$/, // alfanumerico  1 a 40 caracteres
        numero: /^[0-9]{1,40}$/, // numeros  1 a 40 caracteres
        tarjeta: /^[0-9]{4,40}$/, // numeros  4 a 40 caracteres
        rut: /^(\d{1,2}\.\d{3}\.\d{3}-)([kK]{1}$|\d{1}$)/, // rut chileno
        // rut: /^(\d{1,2}(?:[\.]?\d{3}){2}-[\dkK])$/, // prueba jackson
        rutID: /^((\d{1,2}\.\d{3}\.\d{3}-)([kK]{1}$|\d{1}$)|[a-zA-Z0-9]{1,40})$/, // rut chileno o un id
        rutUser:
            /^((\d{1,2}\.\d{3}\.\d{3}-)([kK]{1}$|\d{1}$)|no especificado{1,40})$/, // rut chileno o un id
        genero: /^[a-zA-Z0-9\_\-]{1,2}/, // de cero a 2 caracteres
        blanco: /^$/, // en blanco
        ipAdressV4:
            /^((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$/, // ip version 4
        ipAdressV6: /^([\da-fA-F]{1,4}:){7}[\da-fA-F]{1,4}$/, //ip verion 6
        todo: /\S{1,40}/,
        userCorreo:
            /^([a-zA-Z0-9\_\-]|[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+){4,40}$/, // para validar o usuarios o correo
    };
    #campos = {};
    #idBtnCopia = '';

    constructor(configuracion) {
        this.configuracion = configuracion;
        this.items = configuracion.items;
        this.idMensaje = configuracion.idMensaje;
        this.idBoton = configuracion.idBoton;
    }
    // Getter
    get esValido() {
        this.#hacerFocus();
        this.#verificarValidar();

        let comprobar = Object.values(this.#campos);

        if (!comprobar.includes(false)) {
            this.flag = true;
            document
                .querySelectorAll('.formulario__grupo-correcto')
                .forEach((icono) => {
                    icono.classList.remove('formulario__grupo-correcto');
                });
        } else {
            this.flag = false;
            document
                .getElementById(this.idMensaje)
                .classList.add('formulario__mensaje-activo');
            setTimeout(() => {
                document
                    .getElementById(this.idMensaje)
                    .classList.remove('formulario__mensaje-activo');
            }, 5000);
        }
        return this.flag;
    }

    #insertarClaseMensajeError() {
        let msjNoValido = document.getElementById(`${this.idMensaje}`);
        if (msjNoValido) {
            msjNoValido.classList.add('formulario__mensaje');
        }
    }

    #copiarBorrarBoton() {
        let btnOriginal = document.getElementById(`${this.idBoton}`);
        if (btnOriginal) {
            let btnCopia = btnOriginal.cloneNode(true);
            // let btnCopia = document.createElement('input');
            btnCopia.id = btnOriginal.id + 'copia';
            // btnCopia.classList.value = btnOriginal.classList.value;
            btnCopia.type = 'button';
            // btnCopia.value = btnOriginal.innerHTML;

            btnOriginal.insertAdjacentElement('beforebegin', btnCopia);
            btnOriginal.style = 'display: none;';
            // btnOriginal.setAttribute('disabled', '');
            this.#idBtnCopia = btnCopia.id;
        }
    }

    #insertarElementos(key) {
        /* agregar clases */
        let label = document.getElementById(this.items[key].label);
        if (label) {
            label.classList.add('formulario__label');
        }

        let div = document.getElementById(`validar__${key}`);
        div.classList.add('formulario__grupo-input');

        let item = document.getElementById(`${key}`);
        item.classList.add('formulario__input');

        /* Icono de validacion */
        let icono = document.createElement('i');
        // creando el atributo class:
        const iconoClass = document.createAttribute('class');
        // agregando clases al tributo:
        iconoClass.value = 'formulario__validacion-estado fa fa-times-circle';
        icono.setAttributeNode(iconoClass);
        div.appendChild(icono);

        /* Parrafo de error */
        let msjError = document.createElement('p');
        // creando el atributo class:
        const att = document.createAttribute('class');
        // agregando clases al tributo:
        att.value = 'formulario__input-error';
        msjError.setAttributeNode(att);

        msjError.innerHTML = `${this.items[key].texto}`;

        if (typeof this.items[key].texto !== 'undefined') {
            document.getElementById(`validar__${key}`).appendChild(msjError);
        }
    }

    #validarCampo(key) {
        let expresion = this.#expresiones[this.items[key].expresion];
        let input = document.getElementById(key);

        if (this.items[key].expresion === 'rut') {
            if (expresion.test(input.value)) {
                let rutValido = false;
                let valorRUT = input.value;
                valorRUT = valorRUT.replaceAll('.', '');

                VerificarRUT.validaRut(valorRUT)
                    ? (rutValido = true)
                    : (rutValido = false);
                this.#campoEsValido(rutValido, input);
            } else {
                this.#campoEsValido(false, input);
            }
        } else {
            this.#campoEsValido(expresion.test(input.value), input);
        }
    }

    #validarCampoEnBlanco(key) {
        let expresion = this.#expresiones.blanco;
        let input = document.getElementById(key);

        this.#campoEsValido(expresion.test(input.value), input);
    }

    #campoEsValido(valido, input) {
        if (valido) {
            document
                .getElementById(`validar__${input.id}`)
                .classList.remove('formulario__grupo-incorrecto');
            document
                .getElementById(`validar__${input.id}`)
                .classList.add('formulario__grupo-correcto');
            document
                .querySelector(
                    `#validar__${input.id} .formulario__validacion-estado`
                )
                .classList.add('fa-check-circle');
            document
                .querySelector(
                    `#validar__${input.id} .formulario__validacion-estado`
                )
                .classList.remove('fa-times-circle');
            document
                .querySelector(`#validar__${input.id} .formulario__input-error`)
                .classList.remove('formulario__input-error-activo');
            if (this.items[input.id].validarSiempre) {
                this.#campos[input.id] = true;
            } else {
                this.#campos[input.id] = true;
            }
        } else {
            document
                .getElementById(`validar__${input.id}`)
                .classList.add('formulario__grupo-incorrecto');
            document
                .getElementById(`validar__${input.id}`)
                .classList.remove('formulario__grupo-correcto');
            document
                .querySelector(
                    `#validar__${input.id} .formulario__validacion-estado`
                )
                .classList.add('fa-times-circle');
            document
                .querySelector(
                    `#validar__${input.id} .formulario__validacion-estado`
                )
                .classList.remove('fa-check-circle');
            document
                .querySelector(`#validar__${input.id} .formulario__input-error`)
                .classList.add('formulario__input-error-activo');
            if (this.items[input.id].validarSiempre) {
                this.#campos[input.id] = true;
            } else {
                this.#campos[input.id] = false;
            }
        }
    }

    #validarSelects(key) {
        // funcion para validar select razor
        this.#validarCampo(key);
    }

    #formatoDeFechas(date, key = '') {
        switch (key) {
            case 'dd-mm-yyy':
                let dateComponents = date.split('T');
                let datePieces = dateComponents[0].split('-');
                // let timePieces = dateComponents[1].split(':');
                // let timePieces = dateComponents[1].split(':');
                date = new Date(
                    datePieces[2],
                    datePieces[1] - 1,
                    datePieces[0]
                    // timePieces[0],
                    // timePieces[1],
                    // timePieces[2]
                );
                date = Date.parse(date);
                return date;

            case 'time':
                return date;
            default:
                date = Date.parse(date);
                return date;
        }
    }
    // modificado
    #validarFechas(key) {
        let hoy = Date.now();
        hoy = new Date(hoy);
        hoy =
            hoy.getFullYear() +
            '-' +
            (hoy.getMonth() + 1) +
            '-' +
            hoy.getDate();
        hoy = Date.parse(hoy);

        let input = document.getElementById(key);
        let fecha = this.#formatoDeFechas(
            input.value,
            this.items[key].fechaFormato
        );

        if (this.items[key].compararCon === 'hoy') {
            if (this.items[key].condicion === 'menor') {
                if (fecha < hoy) {
                    this.#campoEsValido(true, input);
                } else {
                    this.#campoEsValido(false, input);
                    // this.#campoEsValido(true, input);
                }
            } else if (this.items[key].condicion === 'mayor') {
                if (fecha > hoy) {
                    this.#campoEsValido(true, input);
                } else {
                    this.#campoEsValido(false, input);
                    // this.#campoEsValido(true, input);
                }
            } else {
                if (fecha >= hoy) {
                    this.#campoEsValido(true, input);
                } else if (fecha <= hoy) {
                    this.#campoEsValido(true, input);
                }
            }
        } else {
            let input2 = document.getElementById(this.items[key].compararCon);
            let fecha2 = this.#formatoDeFechas(
                input2.value,
                this.items[key].fechaFormato
            );

            if (this.items[key].condicion === 'menor') {
                if (fecha < fecha2) {
                    this.#campoEsValido(true, input);
                } else {
                    this.#campoEsValido(false, input);
                    // this.#campoEsValido(true, input);
                }
            } else if (this.items[key].condicion === 'mayor') {
                if (fecha > fecha2) {
                    this.#campoEsValido(true, input);
                } else {
                    this.#campoEsValido(false, input);
                    // this.#campoEsValido(true, input);
                }
            }
        }
    }

    #validarPassword(key) {
        let input = document.getElementById(key);
        let input2 = document.getElementById(this.items[key].compararCon);

        if (input.value === input2.value) {
            this.#campoEsValido(true, input);
        } else {
            this.#campoEsValido(false, input);
        }
    }

    #tipoDeValidacion(key) {
        if (this.items[key].select) {
            this.#validarSelects(key);
        } else if (this.items[key].fecha) {
            this.#validarFechas(key);
        } else if (this.items[key].password) {
            this.#validarPassword(key);
        } else {
            this.#validarCampo(key);
        }
    }

    #esCampoOpcional(key) {
        if (this.items[key].opcional) {
            let input = document.getElementById(key);

            input.value.length == 0
                ? this.#validarCampoEnBlanco(key)
                : this.#tipoDeValidacion(key);
        } else {
            this.#tipoDeValidacion(key);
        }
    }

    #validarFormulario(e) {
        let key = e.target.id;
        if (document.getElementById(key)) {
            this.#esCampoOpcional(key);
        }
    }

    #VerificarFormulario(key) {
        if (document.getElementById(key)) {
            this.#esCampoOpcional(key);
        }
    }

    #hacerFocus() {
        for (const [key, element] of Object.entries(this.items)) {
            if (document.getElementById(key)) {
                document.getElementById(key).focus();
                document.getElementById(key).blur();
            }
        }
    }

    #verificarValidar() {
        for (const [key, element] of Object.entries(this.items)) {
            this.#VerificarFormulario(key);
        }
    }

    iniciar() {
        for (const [key, element] of Object.entries(this.items)) {
            if (document.getElementById(key)) {
                let input = document.getElementById(key);

                this.#campos[key] = false;

                this.#insertarElementos(key);

                input.addEventListener(
                    'keyup',
                    this.#validarFormulario.bind(this)
                );
                input.addEventListener(
                    'blur',
                    this.#validarFormulario.bind(this)
                );
                input.addEventListener(
                    'change',
                    this.#validarFormulario.bind(this)
                );
            }
        }

        this.#insertarClaseMensajeError();
        this.#copiarBorrarBoton();

        let boton = document.getElementById(this.#idBtnCopia);
        if (boton) {
            boton.addEventListener('click', (e) => {
                this.#hacerFocus();
                this.#verificarValidar();

                let comprobar = Object.values(this.#campos);

                if (!comprobar.includes(false)) {
                    this.flag = true;
                    document
                        .querySelectorAll('.formulario__grupo-correcto')
                        .forEach((icono) => {
                            icono.classList.remove(
                                'formulario__grupo-correcto'
                            );
                        });
                    document.getElementById(this.idBoton).click();
                } else {
                    this.flag = false;
                    document
                        .getElementById(this.idMensaje)
                        .classList.add('formulario__mensaje-activo');
                    setTimeout(() => {
                        document
                            .getElementById(this.idMensaje)
                            .classList.remove('formulario__mensaje-activo');
                    }, 5000);
                }
            });
        }
    }

    reset() {
        document
            .querySelectorAll('.formulario__grupo-correcto')
            .forEach((element) => {
                element.classList.remove('formulario__grupo-correcto');
                document.querySelector('.formulario__input-error-activo');
            });
        document
            .querySelectorAll('.formulario__grupo-incorrecto')
            .forEach((element) => {
                element.classList.remove('formulario__grupo-incorrecto');
                document
                    .querySelector('.formulario__input-error-activo')
                    .classList.remove('formulario__input-error-activo');
            });
        document
            .getElementById(this.idMensaje)
            .classList.remove('formulario__mensaje-activo');
    }
};

let VerificarRUT = {
    // Valida el rut con su cadena completa "XXXXXXXX-X"
    validaRut: function (rutCompleto) {
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
        var tmp = rutCompleto.split('-');
        var digv = tmp[1];
        var rut = tmp[0];
        if (digv == 'K') digv = 'k';
        return VerificarRUT.dv(rut) == digv;
    },
    dv: function (T) {
        var M = 0,
            S = 1;
        for (; T; T = Math.floor(T / 10))
            S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
        return S ? S - 1 : 'k';
    },
};

// Uso de la función
// alert( VerificarRUT.validaRut('20593758-9') ? 'Valido' : 'inválido');
